.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingDot {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: #0056b3; /* Primary color */
  border-radius: 50%;
  animation: loading 0.6s infinite alternate;
}

.loadingDot:nth-child(2) {
  animation-delay: 0.2s;
}

.loadingDot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loading {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0.5;
  }
}
